import React from 'react'
import ReactDOM from 'react-dom/client'

import './index.css'
import App from './App'

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
)

// Check Location Permissions at beginning of session
const errorCallback = (error) => {
    if (error.code === error.PERMISSION_DENIED) {
        alert('LOCATION PERMISSIONS DENIED. PLEASE ALLOW AND TRY AGAIN.')
    }
}
navigator.geolocation.getCurrentPosition((pos) => {}, errorCallback)
setTimeout(()=>{
    document.title = "---";
},1000);
