import React from 'react'

import {AFrameScene} from '../lib/aframe-components'
import html from './cube.html'
const namedWayspotComponent = {
    schema: {
        name: {type: 'string'},
    },
    init() {
        console.log('INIT WAYSPOT COMPONENT');
        const {object3D} = this.el
        const {name} = this.data
        this.el.sceneEl.addEventListener('realityready', () => {
            document.title = "Ready";
            object3D.visible = false
        })
        this.el.sceneEl.addEventListener('xrimageloading', () => {
            document.title = "Loading model..";
        })

        const foundWayspot = ({detail}) => {
            document.title = "Model found";
            if (name !== detail.name) {
                return
            }
            object3D.position.copy(detail.position)
            object3D.quaternion.copy(detail.rotation)
            object3D.visible = true
        }

        const lostWayspot = ({detail}) => {
            if (name !== detail.name) {
                return
            }
            object3D.visible = false
        }

        this.el.sceneEl.addEventListener('xrprojectwayspotfound', foundWayspot)
        this.el.sceneEl.addEventListener('xrprojectwayspotlost', lostWayspot)
    },
}

// Plays VPS animation after vps-coaching-overlay has disappeared
const playVpsAnimationComponent = {
    init() {
        const overlayHidden = () => {
            this.el.setAttribute('animation-mixer', 'clip: *')
        }

        const overlayVisible = () => {
            this.el.removeAttribute('animation-mixer')
        }

        window.XR8.addCameraPipelineModule({
            name: 'vps-coaching-overlay-listen',
            listeners: [
                {event: 'vps-coaching-overlay.hide', process: overlayHidden},
                {event: 'vps-coaching-overlay.show', process: overlayVisible},
            ],
        })
    },
}

const shadowShaderComponent = {
    schema: {
        'opacity': {default: 0.4},
    },
    update() {
        if (typeof AFRAME === 'undefined') {
            throw new Error('Component attempted to register before AFRAME was available.')
        }

        const shadowMaterial = new THREE.ShadowMaterial()
        shadowMaterial.opacity = this.data.opacity
        shadowMaterial.transparent = true
        shadowMaterial.polygonOffset = true
        shadowMaterial.polygonOffsetFactor = -4

        const applyShadowMaterial = (mesh) => {
            if (!mesh) {
                return
            }
            if (mesh.material) {
                mesh.material = shadowMaterial
                mesh.material.needsUpdate = true
            }
            mesh.traverse((node) => {
                if (node.isMesh) {
                    node.material = shadowMaterial
                }
            })
        }

        this.el.getObject3D('mesh') ? applyShadowMaterial(this.el.getObject3D('mesh')) : this.el.addEventListener('model-loaded', () => {
            applyShadowMaterial(this.el.getObject3D('mesh'))
            this.el.object3D.traverse((obj) => {
                obj.frustumCulled = false
            })
        })
    },
}

const hiderMaterialComponent = {
    init() {
        const hiderMaterial = new THREE.MeshStandardMaterial()
        hiderMaterial.colorWrite = false

        const applyHiderMaterial = (mesh) => {
            if (!mesh) {
                return
            }
            if (mesh.material) {
                mesh.material = hiderMaterial
            }
            mesh.traverse((node) => {
                if (node.isMesh) {
                    this.mat = node.material
                    node.material = hiderMaterial
                }
            })
        }

        applyHiderMaterial(this.el.getObject3D('mesh'))
        this.el.addEventListener(
            'model-loaded', () => applyHiderMaterial(this.el.getObject3D('mesh'))
        )
    },
    remove() {
        const hiderMaterial = new THREE.MeshStandardMaterial()
        hiderMaterial.colorWrite = true

        const applyHiderMaterial = (mesh) => {
            if (!mesh) {
                return
            }
            if (mesh.material) {
                mesh.material = hiderMaterial
            }
            mesh.traverse((node) => {
                if (node.isMesh) {
                    node.material = this.mat
                }
            })
        }

        applyHiderMaterial(this.el.getObject3D('mesh'))
        this.el.addEventListener(
            'model-loaded', () => applyHiderMaterial(this.el.getObject3D('mesh'))
        )
    },
}

const desktopDevelopmentComponent = {
    schema: {
        inspector: {type: 'boolean', default: true},
    },
    init() {
        const onAttach = ({sessionAttributes}) => {
            const s = sessionAttributes
            const isDesktop = !s.cameraLinkedToViewer && !s.controlsCamera && !s.fillsCameraTexture && !s.supportsHtmlEmbedded && s.supportsHtmlOverlay && !s.usesMediaDevices && !s.usesWebXr

            const namedWayspot = document.querySelector('[named-wayspot]')
            const occluder = document.querySelector('[hider-material]')

            const scene = this.el
            const removeXRandExtras = () => {
                scene.removeAttribute('landing-page')
                scene.removeAttribute('xrextras-loading')
                scene.removeAttribute('xrextras-gesture-detector')
                scene.removeAttribute('xrextras-runtime-error')
                scene.removeAttribute('vps-coaching-overlay')

                namedWayspot.removeAttribute('named-wayspot')
                occluder.removeAttribute('hider-material')

                scene.removeAttribute('xrweb')

                if (this.data.inspector) {
                    scene.components.inspector.openInspector()
                    scene.renderer.autoClearColor = true
                }
            }

            if (isDesktop) {
                removeXRandExtras()
            }
        }
        const onxrloaded = () => {
            XR8.addCameraPipelineModules([{'name': 'desktopDevelopment', onAttach}])
        }
        window.XR8 ? onxrloaded() : window.addEventListener('xrloaded', onxrloaded)
    },
}

const components = [
    { name: 'named-wayspot', val: namedWayspotComponent },
    { name: 'play-vps-animation', val: playVpsAnimationComponent },
    { name: 'shadow-shader', val: shadowShaderComponent },
    { name: 'desktop-development', val: desktopDevelopmentComponent },
    { name: 'hider-material', val: hiderMaterialComponent },
];

const Scene = () => (
  <AFrameScene sceneHtml={html} components={components} />
)

export {Scene}
